






















































import { Vue, Component, Watch, PropSync, Ref } from 'vue-property-decorator'
import { FindFornecedorUseCase } from '@/usecases'
import { Fornecedor, Page, Pageable } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'

@Component
export default class SeletorDeFornecedor extends Vue {
	@Ref() campo!: HTMLInputElement
	@PropSync('value') selecionado?: Fornecedor | string
	
	fornecedores: Fornecedor[] = []
	busca: string | null = null
	findFornecedorUseCase = new FindFornecedorUseCase()
	cancelToken: CancelTokenSource | null = null

	pageable: Pageable = {
		page: 0,
		size: 3,
	}
	pagina: Page<Fornecedor> | null = null
	carregando = true

	created() {
		this.carregarSelecao()
	}

	extrairId(objeto: Fornecedor | string): string {
		return typeof objeto === 'object'
			? objeto.id
			: objeto
	}

	async carregarSelecao() {
		if (!this.selecionado) {
			return this.find()
		}
		const busca: string[] = Array.isArray(this.selecionado)
			? (this.selecionado as (string | Fornecedor)[]).map(this.extrairId)
			: [this.extrairId(this.selecionado)]

		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const params = {
				ids: busca,
				busca: busca[0],
			}

			this.pagina = await this.findFornecedorUseCase.find({
				params,
			}, axiosConfig)
			this.pagina.content.forEach(fornecedor => {
				this.fornecedores.push(fornecedor)
			})
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async find() {
		if (this.cancelToken) this.cancelToken.cancel()
		this.busca = removerFormatacaoDeCnpjOuCpf(this.busca)
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			this.pagina = await this.findFornecedorUseCase.find({
				page: this.pageable.page || 0,
				size: this.pageable.size,
				busca: this.busca || undefined,
			},
			{
				cancelToken: this.cancelToken.token,
			})

			this.pagina.content.forEach(fornecedor => {
				if (this.fornecedores.some(({ id }) => fornecedor.id === id)) return
				this.fornecedores.push(fornecedor)
			})
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('busca', { deep: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.find()
	}

	@Watch('selecionado') 
	onChangeSelecionado() {
		if(!this.selecionado) return
		this.carregarSelecao()
	}

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1
		await this.find()
	}

	get carregouTodosOsFornecedores() {
		return this.pagina?.last || false
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}

	focus() {
		this.campo.focus()
	}

}
