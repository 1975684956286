



































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { obrigatorio, maiorQueZero } from '@/shareds/regras-de-form'
import { FormVoucher } from '@/models'
import SeletorDeGruposEconomicos from '@/components/loja/SeletorDeGruposEconomicos.vue'

@Component({
	components: {
		CampoDecimal,
		SeletorDeGruposEconomicos,
	},
})
export default class DialogoVoucherAvulso extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() campoDeValor!: HTMLInputElement
	@Prop({ type: String, required: true }) clienteId!: string
	obrigatorio = obrigatorio
	maiorQueZero = maiorQueZero
	voucherForm: FormVoucher | null = null
	mostra = false
	salvando = false

	mostrar() {
		this.voucherForm = {
			clienteId: this.clienteId,
			dataHoraGeracao: new Date,
			valor: 0.00,
			expiracao: null,
			situacao: 'Aberto',
			grupoEconomico: {
				id: '',
				identificador: '',
				descricao: '',
			},
			cashbackId: '',
			statusMsg: '',
			acaoManutencao: '',
		}
		this.mostra = true
		setTimeout(() => {
			this.campoDeValor.focus()
			setTimeout(() => {
				this.form.resetValidation()
			})
		})
	}

	saving(salvando = true) {
		this.salvando = salvando
	}

	esconder() {
		this.mostra = false
	}
}
